import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { Parallax } from 'react-scroll-parallax';
const szemuveglencsek = () => (
  <Layout>
    <SEO title="Szemüveglencsék" description="Egyénre szabott optika Zugló szívében"  />
    <div className="sitewrap xl:max-w-2xl justify-center  mr-auto ml-auto      mt-10 ">
    <div className="  flex    mr-auto ml-auto   mt-20">
    <div className="F12F7B p-4      mb-10 mt-10 ">
    



<div className="left2 mt-20 "><h3 className="maintext2 left2 ">Az Essilor cég szemüveglencséit forgalmazzuk.</h3>
<h3 className="maintext2 mt-2 left2">
Az Essilor fejlesztette ki a Multifokális és Transitions - fényre sötétedő lencséket.</h3>

  
<ul className="maintext2 mt-2 left2 text-left"> 
<li>Egész napos távoli viseletre, akár vékonyított, páramentes kivitelben</li>
<li>Progresszív - multfifokális : VARILUX család</li>
<li>Munka és olvasó lencsék</li>
<li>Napvédő lencsék</li>
<ul className="maintext2 mt-2 left2 text-left"> 
<li>színezett</li>
<li>fényre sötétedő</li>
<li>polarizált</li>
<li>tükrös réteggel</li>
</ul>
<li>Autóvezetéshez speciális lencsék, rétegek</li>
<li>Sport lencsék (bázis, alapanyag)</li>

</ul> 
<h3 className="maintext2 font-bold mt-10  text-left text-justify"> 
A lencseválasztásban segítünk Önnek, bemutató demó lencsékkel. </h3> 








</div></div>

    </div></div>
  </Layout>
)

export default szemuveglencsek
